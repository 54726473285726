.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 0px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: none;
  font-weight: bold;
}

.ant-menu-submenu-active {
  & .ant-menu-submenu-title {
    & .ant-menu-submenu-arrow {
      color: white;
    }
  }
}

.ant-menu-submenu-open {
  & .ant-menu-submenu-title {
    & span {
      color: white;
      font-weight: bold;
    }
  }
}

.ant-menu-submenu {
  & .ant-menu-submenu-title {
    & span {
      color: white;
    }
    & .ant-menu-submenu-arrow {
      color: white;
      &::before {
        color: white;
      }
      &::after {
        color: white;
      }
    }
  }
  & .ant-menu {
    background-color: transparent;
  }
}

.ant-modal-wrap .log-out,
.ant-modal-wrap .custom-modal {
  font-family: 'WorkSans', sans-serif;
  .ant-modal-content {
    background-color: white;
    padding: 10px 30px;
    .ant-modal-body {
      padding: 0;
      width: 100%;
      height: 100%;
      .ant-modal-confirm-body-wrapper {
        padding: 0px 20px;
        width: 100%;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .ant-modal-confirm-btn {
          width: fit-content;
          display: flex;
          justify-content: center;
          gap: 10px;
        }
      }
    }
  }
}

#scrollbar-sidebar::-webkit-scrollbar {
  display: none;
  width: 4px;
}

#scrollbar-sidebar::-webkit-scrollbar-track {
  margin-top: 16px;
  border-radius: 99px;
  background-color: #363636;
  border: 1px solid #363636;
}

#scrollbar-sidebar::-webkit-scrollbar-thumb {
  height: 1px;
  cursor: pointer;
  border-radius: 99px;
  background-color: white;
}

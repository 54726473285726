.ant-card {
  border-radius: 11px;
  min-height: 262px;
  background-color: #ffffff;
  box-shadow: 0px 4px 5px #dbdbdb;
}

.keep-scrolling {
  overflow-y: auto;
  /* Add the ability to scroll the y axis */
}

/* Hide the scrollbar for Chrome, Safari and Opera */
.keep-scrolling::-webkit-scrollbar {
  display: none;
}

/* Hide the scrollbar for Internet Explorer, Edge and Firefox */
.keep-scrolling {
  -ms-overflow-style: none;
  /* Internet Explorer and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.ant-modal-confirm-btns {
  display: flex;
  align-items: center;
  justify-content: center;
}

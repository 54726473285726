.user-input {
  border-radius: 8px;
  padding: 12px;
}

div.edit-user-detail-modal {
  margin: 0px !important;
  padding: 0px !important;
}

div.select-input div.ant-select-selector {
  border-color: #dadada !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  height: 51px !important;
}
div.select-input div.ant-select-selector:hover {
  border-color: #4d4479 !important;
}

div.detail-container{
    box-shadow: 0px 0px 30px 0px #00000021;

}
.underline-container{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.slide-left {
    animation: 1s slide-left;
  }
  @keyframes slide-left {
    from {
      margin-left: 100%;
    }
    to {
      margin-left: 0%;
    }
  }

  .slide-right {
    animation: 1s slide-right;
  }
  @keyframes slide-right {
    from {
      margin-left: -100%;
    }
    to {
      margin-left: 0%;
    }
  }


  .slide-left-detail {
    animation: 1s slide-left-detail;
  }
  @keyframes slide-left-detail {
    0% { 
        transform: translateX(100%); /* End at the original position */
      }
      100% {
        transform: translateX(0); /* Start off-screen */
      
      }
  }

  .slide-right-detail {
    animation: 1s slide-right-detail;
  }
  @keyframes slide-right-detail {
    0% { 
        transform: translateX(0); /* End at the original position */    
      }
      100% {
        transform: translateX(100%); /* Start off-screen */
      
      }
  }
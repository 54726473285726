@font-face {
  font-family: 'WorkSans';
  src: url('./WorkSans/WorkSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}


@font-face {
  font-family: 'WorkSans';
  src: url('./WorkSans/WorkSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}


@font-face {
  font-family: 'WorkSans';
  src: url('./WorkSans/WorkSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}


@font-face {
  font-family: 'WorkSans';
  src: url('./WorkSans/WorkSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}



@font-face {
  font-family: 'WorkSans';
  src: url('./WorkSans/WorkSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}



@font-face {
  font-family: 'WorkSans';
  font-style: normal;
  font-weight: 300;
  src: url('./WorkSans/WorkSans-Light.ttf') format('truetype');
}


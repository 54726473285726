div.table-user-profile table thead.ant-table-thead {
  background-color: #766ba8;
  & tr th.ant-table-cell:first-child {
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
  }
  & tr th.ant-table-cell:last-child {
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
  }
  & tr th.ant-table-cell {
    color: white;
  }
  & tr th.ant-table-cell::before {
    display: none !important;
  }
  & tr th.ant-table-cell.ant-table-cell-scrollbar {
    display: none !important;
  }
}

div.diagnostic-detail-container {
  border-bottom: 1px solid #dadada !important;
}
p.list-diagnostic-container::before {
  content: '• ';
}
div.collapse-panel-container div.ant-collapse-content-box {
  background-color: #f5f5f5;
}
div.collapse-panel-container div.ant-collapse-header {
  flex-direction: row-reverse;
  font-size: 20px;
  color: #2f2f2f;
  font-weight: bold;
}

div.table-user-profile div.ant-table-header table thead.ant-table-thead {
  background-color: #766ba8;
  & tr th.ant-table-cell.column-first-item {
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
  }
  & tr th.ant-table-cell.column-last-item {
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
  }
  & tr th.ant-table-cell {
    color: white;
  }
  & tr th.ant-table-cell::before {
    display: none !important;
  }
  & tr th.ant-table-cell.ant-table-cell-scrollbar {
    display: none !important;
  }
}
div.table-user-profile table tbody.ant-table-tbody {
  & td.ant-table-cell.column-first-item {
    border: 1px solid #dadada !important;
    border-right: 0 !important;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
  }
  & td.ant-table-cell {
    border: 1px solid #dadada !important;
    border-right: 0 !important;
    border-left: 0 !important;
  }
  & td.ant-table-cell.column-last-item {
    border: 1px solid #dadada !important;
    border-left: 0 !important;
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
  }
}
div.table-user-profile .ant-table table {
  border-spacing: 0px 12px;
}
.checkbox-diagnostic .ant-checkbox-disabled .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  cursor: default;
  border-color: #4d4479 !important;
  border-width: 2px;
  border-style: solid;
  background-color: #cdcfd9;
}

.checkbox-diagnostic .ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #4d4479 !important;
}

@import './configs/theme.var.scss';
@import './assets/fonts/font';

.ant-btn-success {
  color: #fff;
  border-color: $success-color;
  background: $success-color;
}

.ant-btn-success:hover,
.ant-btn-success:focus {
  color: #fff;
  border-color: $success-secondary-color;
  background: $success-secondary-color;
}

.ant-btn-error {
  color: #fff;
  border-color: $error-color;
  background: $error-color;
}

.ant-btn-error:hover,
.ant-btn-error:focus {
  color: #fff;
  border-color: $error-secondary-color;
  background: $error-secondary-color;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

body {
  margin: 0;
  padding: 0;
}

.ant-form-item-explain-error {
  font-size: 16px;
  color: $error-color;
  margin-bottom: 5px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
  border-radius: 20px;
  margin-block: 0.5rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  width: 7px;
  background: $primary-color;
  border-radius: 20px;
  border-right: 1px solid white;
  &:hover {
    cursor: pointer;
    background: linear-gradient(to, #4d4479, #e2e1ea);
  }
}

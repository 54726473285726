.custom-select-modal .ant-select-selector {
  border-color: white !important;
  border-radius: 10px !important;
  border-style: solid !important;
  border-width: 1px !important;
  background-color: #4d4479 !important;
  height: 50px !important;
  color: white !important;
}

span.button-product-price input {
  height: 50px !important;
  border-width: 1px;
  border-style: solid;
  border-color: white;
  background-color: #4d4479 !important;
  border-radius: 10px;
  color: white;
  border-right: none !important;
}
span.button-product-price input:focus {
  border-color: white;
}
span.button-product-price input:hover {
  border-color: white;
}

span.button-product-price span.ant-input-group-addon {
  background-color: transparent;
  color: white;
  border-color: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

div.view-list-container{
    border-right: 1px solid #F0F0F0;
}

div.view-detail-chat-header{
    border-bottom: 1px solid #F0F0F0;
}

div.view-detail-chat-header{
    border-bottom: 1px solid #F0F0F0;
}
div.reply-chat-container{
    border-bottom: 2px solid #F0F0F0;
}

ul.rating-container li.ant-rate-star-full{
    margin-right: 12px!important;
}

div.ant-dropdown ul.ant-dropdown-menu{
    border-radius: 12px;
}
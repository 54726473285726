@import "../../configs/theme.var.scss";
#login-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 400px;
  height: 90vh;

  .login-form {
    max-width: 512px;
    max-height: 547px;
    padding: 100px;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }
  .login-form-forgot {
    float: right;
  }
  .ant-col-rtl .login-form-forgot {
    float: left;
  }
  .login-form-button {
    width: 100%;
  }

  .login-logo {
    padding-bottom: 50px;
  }
  .custom-login-text{
    font-size: 32px;
    font-style: bold;
  }

  .login-form-header{
    padding-bottom: 30px;
  }

  .login-form-body{
    padding-bottom: 15px;
  }
  .custom-btn-login{
    height: 56px;
    border-radius: 8px;
  }
}
.custom-input{
  height: 56px;
  border-radius: 8px;
  border-color: #000000 ;
}
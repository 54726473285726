.skeleton-container {
    display: flex;
    align-items: center;
  }
  
  .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ccc;
    margin-right: 8px;
  }
  
  /* Animation styles */
  .dot-1 {
    animation: skeleton-loading 1s infinite;
  }
  
  .dot-2 {
    animation: skeleton-loading 1s infinite 0.33s;
  }
  
  .dot-3 {
    animation: skeleton-loading 1s infinite 0.66s;
  }
  
  @keyframes skeleton-loading {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
  
@import '../../../../configs/theme.var.scss';

.accessIndividual .content .ant-form-item-label {
  margin-bottom: 10px;
  & label {
    font-family: 'WorkSans';
    font-weight: 500;
    color: $primary-color;
    font-size: 20px;
  }
}
.ant-form-item-has-error {
  background-color: transparent;
}

.accessIndividual .content .ant-form-item,
.accessIndividual .content .ant-form-item-has-error {
  & .ant-form-item-control-input-content .ant-input-affix-wrapper {
    background: none;
    height: fit-content;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 5px;
    border: 1px solid $primary-color;

    & input {
      background: none;
      color: $primary-color;
    }

    font-size: 16px;
  }
}

.accessIndividual .content .ant-form-item-control-input-content .ant-input-textarea {
  width: 100%;
  height: 10rem;
  & textarea {
    resize: none;
    width: 100%;
    height: 100%;
    padding: 1rem;
    font-size: 16px;
    background-color: transparent;
    color: $primary-color;
    border: 1px solid $primary-color;
    border-radius: 0.5rem;
  }
}

.accessIndividual .content .ant-form-item,
.accessIndividual .content .ant-form-item-has-error {
  & .ant-form-item-control-input-content .ant-input-number-in-form-item {
    background: none;
    height: fit-content;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 5px;
    border: 1px solid $primary-color;
    font-size: 16px;

    & .ant-input-number-handler-wrap {
      background: none;
      display: flex;
      flex-direction: column;
      height: fit-content;
      gap: 10px;
      margin-right: 1rem;
      margin-top: 0.5rem;
      font-size: 16px;
      & span {
        border: none;
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $primary-color;
      }
    }

    & .ant-input-number-input-wrap {
      & input {
        background: none;
        padding-left: 1rem;
        font-size: 16px;
        color: $primary-color;
      }
    }
  }
}

.accessIndividual .content .ant-picker {
  width: 500px;
  height: 55px;
  border-radius: 0.5rem;
  & .ant-picker-input {
    & input {
      font-size: 16px;
    }
  }
}

.accessIndividual .content .ant-form-item {
  & .ant-form-item-control-input {
    border-radius: 2px;
    & .ant-form-item-control-input-content .ant-select {
      & .ant-select-selector {
        height: 55px;
        border-radius: 5px;
      }
    }
  }
}

.accessIndividual .content .ant-select {
  height: fit-content;
  border-radius: 5px;
  & .ant-select-selector {
    height: 60px;
    border: 1px solid $primary-color;
    border-radius: 5px !important;
  }
}

.accessIndividual
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  span {
  border-width: 2px !important;
}

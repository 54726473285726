@import '../../../configs/theme.var.scss';

.add-access-code,
.edit-access-code {
  & .ant-form-item-label {
    margin-bottom: 10px;
    & label {
      font-family: 'WorkSans';
      font-weight: 500;
      color: $primary-color;
      font-size: 20px;
    }
  }
}
.ant-form-item-has-error {
  background-color: transparent;
}

.add-access-code,
.edit-access-code {
  &.ant-form-item,
  & .ant-form-item-has-error {
    & .ant-form-item-control-input-content .ant-input-affix-wrapper {
      background: none;
      height: fit-content;
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-radius: 5px;
      border: 1px solid $primary-color;

      & input {
        background: none;
        color: $primary-color;
      }

      font-size: 16px;
    }
  }
}

.add-access-code,
.edit-access-code {
  & .ant-form-item-control-input-content .ant-input-textarea {
    width: 100%;
    height: 10rem;
    & textarea {
      resize: none;
      width: 100%;
      height: 100%;
      padding: 1rem;
      font-size: 16px;
      background-color: transparent;
      color: $primary-color;
      border: 1px solid $primary-color;
      border-radius: 0.5rem;
    }
  }
}

.add-access-code,
.edit-access-code {
  & .ant-form-item,
  & .ant-form-item-has-error {
    & .ant-form-item-control-input-content .ant-input-number-affix-wrapper {
      background: none;
      height: fit-content;
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-radius: 5px;
      border: 1px solid $primary-color;
      font-size: 16px;

      & .ant-input-number {
        background: none;
        & .ant-input-number-handler-wrap {
          background: none;
          display: flex;
          flex-direction: column;
          height: fit-content;
          gap: 10px;
          margin-right: 1rem;
          margin-top: -0.1rem;
          font-size: 16px;
          & span {
            border: none;
            background: none;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $primary-color;
          }
        }

        & .ant-input-number-input-wrap {
          & input {
            background: none;
            padding-left: 1rem;
            font-size: 16px;
            color: $primary-color;
          }
        }
      }
    }
  }
}

.view-access-permission {
  & .ant-rate {
    color: $primary-color;
  }
  & .ant-table-thead {
    & .ant-table-cell {
      font-weight: 400;
    }
  }
  & .ant-table-cell {
    border-bottom: 1px solid #E0E0E0;
  }
}

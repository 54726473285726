@import '../configs/theme.var.scss';

.page-container {
  .content-page {
    margin: 0;
    min-height: 300px;
  }
}

.ant-menu,
.ant-menu-root,
.ant-menu-vertical {
  background: $primary-color;
}

.ant-layout {
  background: $primary-color;
  height: 100vh;
  width: 100vw;
}

.slide-left-rating {
    animation: 1s slide-left-rating;
  }
  @keyframes slide-left-rating {
    0% { 
        transform: translateX(100%); /* End at the original position */
       
        
      }
      100% {
        transform: translateX(0); /* Start off-screen */
      
      }
  }

  .slide-right-rating {
    animation: 1s slide-right-rating;
  }
  @keyframes slide-right-rating {
    0% { 
        transform: translateX(0); /* End at the original position */    
      }
      100% {
        transform: translateX(100%); /* Start off-screen */
      
      }
  }

  div.table-user-profile table tbody.ant-table-tbody{
   
    & td.ant-table-cell.column-first-item{
      border: 1px solid #DADADA!important;
      border-right: 0!important;
      border-top-left-radius: 14px;
      border-bottom-left-radius: 14px;
    }
    & td.ant-table-cell{
        border: 1px solid #DADADA!important;
        border-right: 0!important;
        border-left: 0!important;
      }
    & td.ant-table-cell.column-last-item{
        border: 1px solid #DADADA!important;
        border-left: 0!important;
        border-top-right-radius: 14px;
        border-bottom-right-radius: 14px;
      }
}
div.table-user-profile .ant-table table {
    border-spacing: 0px 12px;
}


div.table-user-profile table thead.ant-table-thead {
  background-color: #766BA8;
  & tr th.ant-table-cell:first-child{
      border-top-left-radius: 14px;
      border-bottom-left-radius: 14px;
  }
  & tr th.ant-table-cell:last-child{
      border-top-right-radius: 14px;
      border-bottom-right-radius: 14px;
  }
  & tr th.ant-table-cell{
      color: white;
      font-weight: 700;
  }
  & tr th.ant-table-cell::before{
     display: none!important;
  }

}

.ant-table-thead th.ant-table-column-sort{
  background: none!important;
}
@import '../../../configs/theme.var.scss';

.dailyVibeModal {
  & .ant-select {
    max-width: 545px;
    max-height: 120px;
    border-radius: 15px;
    overflow: auto;
    background-color: white;
    & .ant-select-selector {
      height: 100%;
      max-height: 120px;
      background-color: white;
      border-radius: 15px;
      border: none;
    }
  }

  & .ant-form-item-control-input-content {
    & input {
      margin-top: -10px;
      height: 55px;
      border-radius: 10px;
      background-color: transparent;
      border: 1px dashed white;
      background-color: transparent;
      color: white;
    }
    & input::selection {
      color: black;
      background-color: gainsboro;
    }
  }

  & .ant-switch {
    background-color: #b2b2b2;
    &.ant-switch-checked {
      background-color: $error-secondary-color;
    }
  }
}

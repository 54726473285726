.ant-modal {
  border-radius: 20px;
}

.ant-modal .ant-modal-header {
  border-radius: 20px 20px 0 0;
  border-bottom: none;
  background: #4d4479;
  height: 100%;
  height: 100%;
}

.ant-modal .ant-modal-content {
  border-radius: 20px;
  background-color: #4d4479;
  background-color: #4d4479;
}

.ant-modal-body {
  background: #4d4479;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.upload-file-drop-post {
  margin-left: 20x;
  margin-top: 30px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 450px;
  height: 450px;
  background-color: rgb(245, 245, 245);
  border-radius: 20px;
  float: left;
  border: 2px solid #979797;
  border-radius: 20px;
  border: none;
}
.form-drop-post {
  margin-top: 30px;
  margin-right: 20px;
  margin-left: 15px;
  width: 409px;
  height: 600px;
  float: right;
  color: black;
  .ant-input {
    height: 44px;
    border-radius: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.3px;
    color: #121212;
    border: none;
  }
}
.ant-form-item-label > label {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.3px;
}
.link-product {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #131e46;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.upload-file-image {
  width: 312px;
  height: 390px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #d9d9d9;
  object-fit: cover;
}

.image-exist {
  width: 312px;
  height: 390px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  object-fit: cover;
}

.upload-daily-vibe-audio {
  border: 1px dashed white;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px;
  width: 545px;
}

.audio-exist {
  border: 1px solid white;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.affirmation-input .ant-input-affix-wrapper {
  display: flex;
  align-items: center;
  height: 44px;
  border-radius: 10px;
}

.affirmation-form-item-label .ant-col ant-form-item-label {
  padding: 0;
}

.ant-select .ant-select-selector {
  min-height: 44px;
  border-radius: 10px !important;
}

.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}

.ant-select-selector {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-progress-text {
  color: white;
}

.ant-modal .ant-btn {
  font-size: 20px;
}

.ant-modal .ant-btn:hover {
  background-color: #6f72ac;
}

.affirmation-modal {
  & .ant-select {
    height: fit-content;
    max-height: 120px;
    border-radius: 15px;
    overflow: auto;
    background-color: white;
    & .ant-select-selector {
      height: 100%;
      max-height: 120px;
      background-color: white;
      border-radius: 15px;
      border: none;
    }
  }

}

div.create-affirmation-modal span.ant-input-affix-wrapper{
  border-radius: 15px!important;
  height: 45px;
}